import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { AgGridReact } from "ag-grid-react";
import React, { useState } from "react";
import {
  Button,
  Header,
  Image,
  Modal,
  Segment,
  Table,
  Card,
} from "semantic-ui-react";
import CompanyPage from "../company/CompanyPage";
import "../css/home.css";
import Utils from "../util/Utils";
import "./styles.css";

export default function StockScreener(props) {
  const [stateValues, setstateValues] = useState({
    open: false,
    modalSymbol: "",
  });

  const showModal = (event) => {
    var col_heading = event.colDef.field;
    if (col_heading === "symbol") {
      //alert("Symbol from AG grid" + event.value);
      setstateValues({ ...stateValues, modalSymbol: event.value, open: true });
    }
  };

  const setOpen = (flag) => {
    setstateValues({ ...stateValues, open: flag });
  };

  const defaultColDef = { resizable: true, floatingFilter: true };

  const overlayLoadingTemplate =
    '<span class="ag-overlay-loading-center">Please wait while data loading</span>';

  const ColourCellRenderer = (props) => (
    <span
      style={{
        color: "blue",
        cursor: "pointer",
        textDecoration: "underline",
      }}
    >
      {props.value}
    </span>
  );

  const columnDefs = [
    {
      headerName: "Symbol",
      field: "symbol",
      filter: "agTextColumnFilter",
      sortable: true,
      width: 60,
      pinned: "left",

      cellRenderer: ColourCellRenderer,
    },

    {
      headerName: "Company Name",
      field: "companyName",
      filter: "agTextColumnFilter",
      sortable: true,
      width: 100,
      pinned: "left",
    },

    {
      headerName: "Recommendation",
      headerTooltip: "Recommendation",
      field: "recommendation",
      filter: "agTextColumnFilter",
      sortable: true,
      width: 100,
      pinned: "left",
    },
    {
      headerName: "Current Price",
      headerTooltip: "Current Price",
      field: "price",
      filter: "agNumberColumnFilter",
      valueFormatter: Utils.ag_currencyFormatter,
      sortable: true,
      width: 120,
      pinned: "left",
    },

    {
      headerName: "Sector",
      headerTooltip: "Sector",
      field: "sector",
      filter: "agTextColumnFilter",
      sortable: true,
      width: 120,
    },
    {
      headerName: "Industry",
      headerTooltip: "Industry",
      field: "industry",
      filter: "agTextColumnFilter",
      sortable: true,
      width: 120,
    },

    {
      headerName: "Country",
      headerTooltip: "Country",
      field: "country",
      filter: "agTextColumnFilter",
      sortable: true,
      width: 80,
    },

    {
      headerName: "Size",
      headerTooltip: "Company Size",
      field: "companySize",
      filter: "agTextColumnFilter",
      sortable: true,
      width: 70,
    },

    {
      headerName: "Market Cap",
      headerTooltip: "Market Cap",
      field: "marketCap",
      filter: "agNumberColumnFilter",
      sortable: true,
      width: 80,
      valueFormatter: Utils.ag_currencyCompactFormatter,
    },
    {
      headerName: "Dividend",
      headerTooltip: "Dividend",
      field: "lastDiv",
      filter: "agNumberColumnFilter",
      valueFormatter: Utils.ag_currencyFormatter,
      sortable: true,
      width: 120,
    },
    {
      headerName: "Dividend Yield",
      headerTooltip: "Dividend Yield",
      field: "dividendYield",
      filter: "agNumberColumnFilter",
      valueFormatter: Utils.ag_RatioFormatter,
      sortable: true,
      width: 110,
    },

    {
      headerName: "Perf 1 day",
      headerTooltip: "Perf to Day",
      field: "perfDay",
      filter: "agNumberColumnFilter",
      sortable: true,
      width: 100,
      valueFormatter: Utils.ag_RatioFormatter,
      cellStyle: Utils.ag_grid_cell_color_neg_val,
    },

    {
      headerName: "Perf to Week",
      headerTooltip: "Perf to Week",
      field: "perfWeek",
      filter: "agNumberColumnFilter",
      sortable: true,
      width: 120,
      valueFormatter: Utils.ag_RatioFormatter,
      cellStyle: Utils.ag_grid_cell_color_neg_val,
    },
    {
      headerName: "Perf to Month",
      headerTooltip: "Perf to Month",
      field: "perfMonth",
      filter: "agNumberColumnFilter",
      sortable: true,
      width: 120,
      valueFormatter: Utils.ag_RatioFormatter,
      cellStyle: Utils.ag_grid_cell_color_neg_val,
    },
    {
      headerName: "Perf to YTD",
      headerTooltip: "Perf to YTD",
      field: "perfYTD",
      filter: "agNumberColumnFilter",
      sortable: true,
      width: 120,
      valueFormatter: Utils.ag_RatioFormatter,
      cellStyle: Utils.ag_grid_cell_color_neg_val,
    },
    {
      headerName: "Perf to Year",
      headerTooltip: "Perf to Year",
      field: "perfYear",
      filter: "agNumberColumnFilter",
      sortable: true,
      width: 120,
      valueFormatter: Utils.ag_RatioFormatter,
      cellStyle: Utils.ag_grid_cell_color_neg_val,
    },

    {
      headerName: "Perf to 5Y",
      headerTooltip: "Perf to 5Y",
      field: "perfYTD5",
      filter: "agNumberColumnFilter",
      sortable: true,
      width: 120,
      valueFormatter: Utils.ag_RatioFormatter,
      cellStyle: Utils.ag_grid_cell_color_neg_val,
    },

    {
      headerName: "Perf to 10Y",
      headerTooltip: "Perf to 10Y",
      field: "perfYTD10",
      filter: "agNumberColumnFilter",
      sortable: true,
      width: 120,
      valueFormatter: Utils.ag_RatioFormatter,
      cellStyle: Utils.ag_grid_cell_color_neg_val,
    },

    {
      headerName: "PE Ratio",
      headerTooltip: "PE Ratio",
      field: "pe",
      sortable: true,
      filter: "agNumberColumnFilter",
      valueFormatter: Utils.ag_CompactFormatter,
      width: 85,
    },

    {
      headerName: "PEG Ratio",
      headerTooltip: "PEG Ratio",
      field: "pegRatio",
      sortable: true,
      filter: "agNumberColumnFilter",
      width: 100,
    },
    {
      headerName: "Bookvalue/Share",
      headerTooltip: "Bookvalue/Share",
      field: "bookPerShare",
      sortable: true,
      filter: "agNumberColumnFilter",
      width: 110,
      valueFormatter: Utils.ag_currencyFormatter,
      cellStyle: Utils.ag_grid_cell_color_neg_val,
    },
    {
      headerName: "Intrinsic Value",
      headerTooltip: "Intrinsic Value",
      field: "intrinsicValue",
      sortable: true,
      filter: "agNumberColumnFilter",
      width: 130,
      valueFormatter: Utils.ag_currencyFormatter,
      cellStyle: Utils.ag_grid_cell_color_neg_val,
    },
    {
      headerName: "Above/Below  Fair Value",
      headerTooltip: "Discount to Fair Value",
      field: "discIntrinsic",
      sortable: true,
      filter: "agNumberColumnFilter",
      width: 180,
      valueFormatter: Utils.ag_RatioFormatter,
      cellStyle: Utils.ag_grid_cell_color_neg_val,
    },
    {
      headerName: "OverValued/UnderValued",
      headerTooltip: "OverValued/UnderValued",
      field: "reasonablyPriced",
      sortable: true,
      filter: "agTextColumnFilter",
      width: 180,
    },

    {
      headerName: "5 Year Sales Growth",
      headerTooltip: "5 Year Sales Growth",
      field: "salesGrowth",
      sortable: true,
      filter: "agNumberColumnFilter",
      width: 150,
      valueFormatter: Utils.ag_RatioFormatter,
      cellStyle: Utils.ag_grid_cell_color_neg_val,
    },

    {
      headerName: "5 Yr Net Income Growth",
      headerTooltip: "5 Yr Net Income Growth",
      field: "netIncomeGrowth",
      filter: "agNumberColumnFilter",
      sortable: true,
      width: 180,
      valueFormatter: Utils.ag_RatioFormatter,
      cellStyle: Utils.ag_grid_cell_color_neg_val,
    },

    {
      headerName: "5 Yr Cashflow Growth",
      headerTooltip: "5 Yr Cashflow Growth",
      field: "cashflowGrowth",
      filter: "agNumberColumnFilter",
      sortable: true,
      width: 180,
      valueFormatter: Utils.ag_RatioFormatter,
      cellStyle: Utils.ag_grid_cell_color_neg_val,
    },

    {
      headerName: "Past EPS Growth",
      headerTooltip: "Past EPS Growth",
      field: "ePSpast5Y",
      sortable: true,
      filter: "agNumberColumnFilter",
      width: 120,
      valueFormatter: Utils.ag_RatioFormatter,
      cellStyle: Utils.ag_grid_cell_color_neg_val,
    },

    {
      headerName: "Future EPS Growth",
      headerTooltip: "Future EPS Growth",
      field: "ePSnext5Y",
      filter: "agNumberColumnFilter",
      sortable: true,
      width: 140,
      valueFormatter: Utils.ag_RatioFormatter,
      cellStyle: Utils.ag_grid_cell_color_neg_val,
    },

    {
      headerName: "ROE",
      field: "roe",
      sortable: true,
      filter: "agNumberColumnFilter",
      width: 85,
      cellStyle: Utils.ag_grid_cell_color_neg_val,
    },

    {
      headerName: "Gross Margin",
      headerTooltip: "Gross Margin",
      field: "grossMargin",
      sortable: true,
      filter: "agNumberColumnFilter",
      width: 130,
      valueFormatter: Utils.ag_RatioFormatter,
      cellStyle: Utils.ag_grid_cell_color_neg_val,
    },

    {
      headerName: "Profit Margin",
      headerTooltip: "Profit Margin",
      field: "profitMargin",
      filter: "agNumberColumnFilter",
      sortable: true,
      width: 130,
      valueFormatter: Utils.ag_RatioFormatter,
      cellStyle: Utils.ag_grid_cell_color_neg_val,
    },

    {
      headerName: "Current Ratio",
      headerTooltip: "Current Ratio",
      field: "currentRatio",
      filter: "agNumberColumnFilter",
      sortable: true,
      width: 130,
    },
  ];

  /*console.log(
    "Screener name:",
    props.portfolio ? props.portfolio.name : "none"
  );*/
  //dont display if all
  const isFullScreener = props.portfolio
    ? props.portfolio.name === "all" || props.portfolio.name === "etfall"
      ? true
      : false
    : false;

  //console.log("Screener isFullScreener:", isFullScreener);

  // const images = require.context("../images/screener-images", true);
  let imageName =
    props.portfolio && props.portfolio.image
      ? props.portfolio.image
      : "portfolio";
  //let imgsrc1 = images(`./${imageName}.jpg`).default;

  let imgsrc = `${process.env.PUBLIC_URL}/images/screener-images/${imageName}.jpg`;

  const imageUrl = props.portfolioImageUrl ? props.portfolioImageUrl : imgsrc;

  return (
    <>
      {isFullScreener ? (
        <div
          style={{
            marginLeft: "30%",
            marginRight: "30%",
          }}
        >
          <Card fluid raised>
            <Card.Content>
              <Card.Description>
                <Header color="blue" textAlign="center" as="h2">
                  Screener Results
                </Header>
              </Card.Description>
            </Card.Content>
          </Card>
        </div>
      ) : (
        <Segment>
          <div
            style={{
              display: isFullScreener ? "none" : "flex",
              justifyContent: "left",
            }}
          >
            <Table basic="very">
              <Table.Body>
                <Table.Row>
                  <Table.Cell collapsing>
                    <Image
                      size="small"
                      style={{
                        mixBlendMode: "multiply",
                      }}
                      src={imageUrl}
                      rounded
                    />
                  </Table.Cell>
                  <Table.Cell verticalAlign="top" width="8">
                    <Header as="h2" color="green">
                      {props.portfolio ? "About " + props.portfolio.name : ""}
                    </Header>
                    <Header.Subheader>
                      <p className="large text">
                        {props.portfolio && props.portfolio.about
                          ? props.portfolio.about
                          : "Companies in " +
                            (props.portfolio ? props.portfolio.name : "this ") +
                            " screener/sector/ETF"}
                      </p>
                    </Header.Subheader>
                  </Table.Cell>
                  <Table.Cell verticalAlign="top">
                    <Header as="h2" color="green">
                      Portfolio Performance
                    </Header>
                    <Header.Subheader>
                      <Card raised fluid>
                        <Table compact="very" unstackable>
                          <Table.Header>
                            <Table.Row
                              style={{
                                backgroundColor: "#A9D0F5",
                                fontWeight: "bold",
                              }}
                            >
                              <Table.Cell singleLine textAlign="center">
                                <Header as="h3">1 Day</Header>
                              </Table.Cell>
                              <Table.Cell singleLine textAlign="center">
                                <Header as="h3">1 Week</Header>
                              </Table.Cell>
                              <Table.Cell singleLine textAlign="center">
                                <Header as="h3"> 1 Month</Header>
                              </Table.Cell>
                              <Table.Cell singleLine textAlign="center">
                                <Header as="h3">YTD</Header>
                              </Table.Cell>
                              <Table.Cell singleLine textAlign="center">
                                <Header as="h3">1 Year</Header>
                              </Table.Cell>
                              <Table.Cell singleLine textAlign="center">
                                <Header as="h3">5 Year</Header>
                              </Table.Cell>
                              <Table.Cell singleLine textAlign="center">
                                <Header as="h3">10 Year</Header>
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell
                                textAlign="center"
                                negative={
                                  props.portfolio
                                    ? props.portfolio.perfDay < 0
                                    : false
                                }
                                positive={
                                  props.portfolio
                                    ? props.portfolio.perfDay >= 0
                                    : false
                                }
                              >
                                <Header
                                  as="h3"
                                  content={
                                    props.portfolio
                                      ? Utils.format_percent(
                                          props.portfolio.perfDay
                                        )
                                      : ""
                                  }
                                />
                              </Table.Cell>
                              <Table.Cell
                                textAlign="center"
                                negative={
                                  props.portfolio
                                    ? props.portfolio.perfWeek < 0
                                    : false
                                }
                                positive={
                                  props.portfolio
                                    ? props.portfolio.perfWeek >= 0
                                    : false
                                }
                              >
                                <Header
                                  as="h3"
                                  content={
                                    props.portfolio
                                      ? Utils.format_percent(
                                          props.portfolio.perfWeek
                                        )
                                      : ""
                                  }
                                />
                              </Table.Cell>
                              <Table.Cell
                                textAlign="center"
                                negative={
                                  props.portfolio
                                    ? props.portfolio.perfMonth < 0
                                    : false
                                }
                                positive={
                                  props.portfolio
                                    ? props.portfolio.perfMonth >= 0
                                    : false
                                }
                              >
                                <Header
                                  as="h3"
                                  content={
                                    props.portfolio
                                      ? Utils.format_percent(
                                          props.portfolio.perfMonth
                                        )
                                      : ""
                                  }
                                />
                              </Table.Cell>
                              <Table.Cell
                                textAlign="center"
                                negative={
                                  props.portfolio
                                    ? props.portfolio.perfYTD < 0
                                    : false
                                }
                                positive={
                                  props.portfolio
                                    ? props.portfolio.perfYTD >= 0
                                    : false
                                }
                              >
                                <Header
                                  as="h3"
                                  content={
                                    props.portfolio
                                      ? Utils.format_percent(
                                          props.portfolio.perfYTD
                                        )
                                      : ""
                                  }
                                />
                              </Table.Cell>
                              <Table.Cell
                                textAlign="center"
                                negative={
                                  props.portfolio
                                    ? props.portfolio.perfYear < 0
                                    : false
                                }
                                positive={
                                  props.portfolio
                                    ? props.portfolio.perfYear >= 0
                                    : false
                                }
                              >
                                <Header
                                  as="h3"
                                  content={
                                    props.portfolio
                                      ? Utils.format_percent(
                                          props.portfolio.perfYear
                                        )
                                      : ""
                                  }
                                />
                              </Table.Cell>

                              <Table.Cell
                                textAlign="center"
                                negative={
                                  props.portfolio
                                    ? props.portfolio.perfYTD5 < 0
                                    : false
                                }
                                positive={
                                  props.portfolio
                                    ? props.portfolio.perfYTD5 >= 0
                                    : false
                                }
                              >
                                <Header
                                  as="h3"
                                  content={
                                    props.portfolio
                                      ? Utils.format_percent(
                                          props.portfolio.perfYTD5
                                        )
                                      : ""
                                  }
                                />
                              </Table.Cell>
                              <Table.Cell
                                textAlign="center"
                                negative={
                                  props.portfolio
                                    ? props.portfolio.perfYTD10 < 0
                                    : false
                                }
                                positive={
                                  props.portfolio
                                    ? props.portfolio.perfYTD10 >= 0
                                    : false
                                }
                              >
                                <Header
                                  as="h3"
                                  content={
                                    props.portfolio
                                      ? Utils.format_percent(
                                          props.portfolio.perfYTD10
                                        )
                                      : ""
                                  }
                                />
                              </Table.Cell>
                            </Table.Row>
                          </Table.Header>
                        </Table>
                      </Card>
                    </Header.Subheader>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        </Segment>
      )}

      <div
        style={{
          height: isFullScreener ? "80vh" : "60vh",
          justifyContent: "center",
          margin: "0.1%",
          marginTop: "1.5px",
        }}
        className={"ag-theme-quartz"}
      >
        <AgGridReact
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          rowData={props.portfolio ? props.portfolio.stockReports : ""}
          //rowBuffer={20}
          pagination={true}
          autoSizePadding={true}
          compact={true}
          overlayLoadingTemplate={overlayLoadingTemplate}
          onCellClicked={showModal}
        ></AgGridReact>
      </div>

      <Modal
        closeOnEscape={true}
        centered={true}
        open={stateValues.open}
        size="fullscreen"
        style={{ marginLeft: "2%" }}
      >
        <Modal.Content scrolling>
          <Modal.Description>
            <CompanyPage symbol={stateValues.modalSymbol} />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button className="ui blue button" onClick={() => setOpen(false)}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}

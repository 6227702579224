import React, { useState } from "react";
import { Card, Header, Segment } from "semantic-ui-react";
import "../../node_modules/react-vis/dist/style.css";
import "../css/home.css";
import { MiniChart } from "react-ts-tradingview-widgets";

export default function Finviz(props) {
  //console.log("Favorites", props.favoritesData.stockReports);
  //alert("in snapshots");
  const sr = props.stockReports;
  const symbols = sr.map((item) => item.symbol).join(",");
  const fvlink =
    "https://finviz.com/screener.ashx?v=211&o=tickersfilter&t=" + symbols;

  const [stateValues, setstateValues] = useState({
    open: false,
    modalSymbol: "",
    loading: true,
  });

  return (
    <div
      id="stockchartsdiv"
      style={{
        minHeight: "74vh",
        marginBottom: "2%",
      }}
    >
      {!props.displayAll ? (
        <Header as="h4">
          <a href={fvlink} target="_blank" rel="noopener noreferrer">
            Open Charts in external website{" "}
          </a>{" "}
        </Header>
      ) : null}
    </div>
  );
}

import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { AgGridReact } from "ag-grid-react";
import React, { useState } from "react";
import { Button, Modal, Card, Header } from "semantic-ui-react";
import CompanyPage from "../company/CompanyPage";
import "../css/home.css";
import Utils from "../util/Utils";
import "./styles.css";

export default function ETFScreener(props) {
  const [stateValues, setstateValues] = useState({
    open: false,
    modalSymbol: "",
  });

  const showModal = (event, symbol) => {
    var col_heading = event.colDef.field;
    if (col_heading === "symbol") {
      //console.log("Symbol from AG grid", symbol.value);
      setstateValues({ ...stateValues, modalSymbol: symbol, open: true });
    }
  };

  const setOpen = (flag) => {
    setstateValues({ ...stateValues, open: flag });
  };

  const defaultColDef = { resizable: true, floatingFilter: true };

  const overlayLoadingTemplate =
    '<span class="ag-overlay-loading-center">Please wait while data loading</span>';

  const ColourCellRenderer = (props) => (
    <span
      style={{
        color: "blue",
        cursor: "pointer",
        textDecoration: "underline",
      }}
    >
      {props.value}
    </span>
  );

  const columnDefs = [
    {
      headerName: "ETF",
      field: "symbol",
      filter: "agTextColumnFilter",
      sortable: true,
      width: 70,
      pinned: "left",
      cellRenderer: ColourCellRenderer,
    },

    {
      headerName: "ETF Name",
      field: "companyName",
      filter: "agTextColumnFilter",
      sortable: true,
      width: 350,
      pinned: "left",
    },
    {
      headerName: "Exchange",
      headerTooltip: "exchange",
      field: "exchangeShortName",
      filter: "agTextColumnFilter",
      sortable: true,
      width: 80,
      pinned: "left",
    },

    {
      headerName: "Market Cap",
      headerTooltip: "Market Cap",
      field: "marketCap",
      filter: "agNumberColumnFilter",
      sortable: true,
      width: 80,
      valueFormatter: Utils.ag_currencyCompactFormatter,
      pinned: "left",
    },
    {
      headerName: "Dividend",
      headerTooltip: "Dividend",
      field: "lastDiv",
      filter: "agNumberColumnFilter",
      valueFormatter: Utils.ag_currencyFormatter,
      sortable: true,
      width: 110,
      pinned: "left",
    },
    {
      headerName: "Current Price",
      headerTooltip: "Current Price",
      field: "price",
      filter: "agNumberColumnFilter",
      valueFormatter: Utils.ag_currencyFormatter,
      sortable: true,
      width: 120,
      pinned: "left",
    },
   
    {
      headerName: "Perf 1 day",
      headerTooltip: "Perf to Day",
      field: "perfDay",
      filter: "agNumberColumnFilter",
      sortable: true,
      width: 100,
      valueFormatter: Utils.ag_RatioFormatter,
      cellStyle: Utils.ag_grid_cell_color_neg_val,
    },

    {
      headerName: "Perf to Week",
      headerTooltip: "Perf to Week",
      field: "perfWeek",
      filter: "agNumberColumnFilter",
      sortable: true,
      width: 120,
      valueFormatter: Utils.ag_RatioFormatter,
      cellStyle: Utils.ag_grid_cell_color_neg_val,
    },
    {
      headerName: "Perf to Month",
      headerTooltip: "Perf to Month",
      field: "perfMonth",
      filter: "agNumberColumnFilter",
      sortable: true,
      width: 120,
      valueFormatter: Utils.ag_RatioFormatter,
      cellStyle: Utils.ag_grid_cell_color_neg_val,
    },
    {
      headerName: "Perf to YTD",
      headerTooltip: "Perf to YTD",
      field: "perfYTD",
      filter: "agNumberColumnFilter",
      sortable: true,
      width: 120,
      valueFormatter: Utils.ag_RatioFormatter,
      cellStyle: Utils.ag_grid_cell_color_neg_val,
    },
    {
      headerName: "Perf to Year",
      headerTooltip: "Perf to Year",
      field: "perfYear",
      filter: "agNumberColumnFilter",
      sortable: true,
      width: 120,
      valueFormatter: Utils.ag_RatioFormatter,
      cellStyle: Utils.ag_grid_cell_color_neg_val,
    },

    {
      headerName: "Perf to 5Y",
      headerTooltip: "Perf to 5Y",
      field: "perfYTD5",
      filter: "agNumberColumnFilter",
      sortable: true,
      width: 120,
      valueFormatter: Utils.ag_RatioFormatter,
      cellStyle: Utils.ag_grid_cell_color_neg_val,
    },

    {
      headerName: "Perf to 10Y",
      headerTooltip: "Perf to 10Y",
      field: "perfYTD10",
      filter: "agNumberColumnFilter",
      sortable: true,
      width: 120,
      valueFormatter: Utils.ag_RatioFormatter,
      cellStyle: Utils.ag_grid_cell_color_neg_val,
    },

  ];

  return (
    <>
      <div
        style={{
          marginLeft: "0.5%",
          marginRight: "0.5%",
        }}
      >
        <Card fluid raised>
          <Card.Content>
            <Card.Description>
              <Header color="blue" textAlign="center" as="h3">
                Index and Sector ETFs Performance
              </Header>
            </Card.Description>
          </Card.Content>
        </Card>
      </div>
      <div
        className="ag-theme-quartz"
        style={{
          margin: "5px",
          height: "80vh",
          justifyContent: "center",
        }}
      >
        <AgGridReact
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          rowData={props.portfolio ? props.portfolio.stockReports : ""}
          pagination={true}
          autoSizePadding={true}
          compact={true}
          overlayLoadingTemplate={overlayLoadingTemplate}
          onCellClicked={showModal}
        ></AgGridReact>
      </div>
      <Modal
        closeOnEscape={true}
        centered={true}
        open={stateValues.open}
        size="fullscreen"
        style={{ marginLeft: "2%" }}
      >
        <Modal.Content scrolling>
          <Modal.Description>
            <CompanyPage symbol={stateValues.modalSymbol} />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button className="ui blue button" onClick={() => setOpen(false)}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}

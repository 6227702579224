import React, { Component } from "react";
import { Card, Icon } from "semantic-ui-react";
import "../../node_modules/react-vis/dist/style.css";
import "../css/home.css";
import Utils from "../util/Utils";

export default class Markets extends Component {
  getColor = (change) => {
    let bgColor = "green";
    if (change < 0) bgColor = "red";

    return bgColor;
  };

  getIcon = (change) => {
    let iconName = "triangle up";
    if (change < 0) iconName = "triangle down";

    return iconName;
  };

  getScreenerName = (name) => {
    if (name === "DOW") return "dowjones_constituent";
    else if (name === "NASDAQ") return "nasdaq_constituent";
    else if (name === "S&P 500") return "sp500_constituent";
    else if (name === "Russell 2000") return "Russel Small Cap 2000 Stocks";
  };

  getCard = (index, name) => {
    if (index) {
      return (
        <Card
          raised
          onClick={
            name !== "VIX"
              ? () =>
                  this.props.onClickIndexFunction(this.getScreenerName(name))
              : null
          }
        >
          <Card.Content
            style={{
              backgroundColor: this.getColor(index.change),
            }}
          >
            <Card.Header
              style={{
                color: "white",
              }}
            >
              {name ? name : index.name.split(" ")[0]}{" "}
              <Icon name={this.getIcon(index.change)}></Icon>(
              {Utils.format_number(index.changesPercentage)}%)
            </Card.Header>
            <Card.Description
              style={{
                backgroundColor: this.getColor(index.change),
                color: "white",
              }}
            >
              {Utils.format_nodecimal(index.price)} (
              {Utils.format_number(index.change)})
            </Card.Description>
          </Card.Content>
        </Card>
      );
    }
  };
  render() {
    //console.log("In Markets js", this.props.gold);

    return (
      <>
        <Card.Group centered itemsPerRow={5} stackable>
          {this.props.indexList
            ? this.getCard(
                this.props.indexList.find(
                  (obj) => obj.name === "Dow Jones Industrial Average"
                ),
                "DOW"
              )
            : ""}
          {this.props.indexList
            ? this.getCard(
                this.props.indexList.find((obj) => obj.name === "S&P 500"),
                "S&P 500"
              )
            : ""}
          {this.props.indexList
            ? this.getCard(
                this.props.indexList.find(
                  (obj) => obj.name === "NASDAQ Composite"
                ),
                "NASDAQ"
              )
            : ""}
          {this.props.indexList
            ? this.getCard(
                this.props.indexList.find((obj) => obj.name === "Russell 2000"),
                "Russell 2000"
              )
            : ""}
          {this.props.indexList
            ? this.getCard(
                this.props.indexList.find(
                  (obj) => obj.name === "CBOE Volatility Index"
                ),
                "VIX"
              )
            : ""}
        </Card.Group>
        <Card.Group centered itemsPerRow={5} stackable>
          {this.props.bitcoin
            ? this.getCard(
                this.props.bitcoin.find((obj) => obj.symbol === "BTCUSD"),
                "Bitcoin"
              )
            : ""}
          {this.props.gold
            ? this.getCard(
                this.props.gold.find((obj) => obj.symbol === "GCUSD"),
                "Gold"
              )
            : ""}
          {this.props.silver
            ? this.getCard(
                this.props.silver.find((obj) => obj.symbol === "SIUSD"),
                "Silver"
              )
            : ""}
          {this.props.oil
            ? this.getCard(
                this.props.oil.find((obj) => obj.symbol === "CLUSD"),
                "Oil"
              )
            : ""}
        </Card.Group>
      </>
    );
  }
}

import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-balham.css"; // Optional Theme applied to the grid

import axios from "axios";
import _ from "lodash";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
  Button,
  Card,
  Dropdown,
  Header,
  Icon,
  Image,
  Menu,
  Search,
} from "semantic-ui-react";
import CompanyPage from "../company/CompanyPage";
import "../css/home.css";
import Constants from "../util/Constants";
import Help from "../util/Help";
import LoadingSpinner from "../util/LoadingSpinner";
import ReleaseNotes from "../util/ReleaseNotes";
import CompareResults from "./CompareResults";
import EarningsCalendar from "./EarningsCalendar";
import ETFScreener from "./ETFScreener";
import HomeContainer from "./HomeContainer";
import IPOCalendar from "./IPOCalendar";
import MainHome from "./MainHome";
import MultiSearch from "./MultiSearch";
import PortfolioPerformance from "./PortfolioPerformance";
import ScreenerContainer from "./ScreenerContainer";
import SnapShots from "./SnapShots";
import StockScreener from "./StockScreener";
import SubmitFeature from "./SubmitFeature";
import WelcomePage from "./WelcomePage";

var srchVal;
//var ag_col_def = AgGridColumnHeader.ag_grid_col_def;

const initialSearchState = {
  isLoading: false,
  symbolResults: [],
  symbolValue: "",
  countryResults: [],
  countryValue: "",
};

const intialDisplayState = {
  loading: false,
  showActivity: false,
  showIndex: false,
  showFavorites: false,
  showScreener: false,
  showETFScreener: false,
  showStck: false,
  showDeepDiveReport: false,
  showPortfolioLeaderboard: false,
  showCmprStcks: false,
  showIPO: false,
  showEarningsCal: false,
  showHelp: false,
  showReleaseNotes: false,
  showSubmitFeature: false,
};

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialSearchState,
      ...intialDisplayState,

      newUser: this.props.newUser,
      nodata: false,
      stockValues: null,
      compareResults: null,
      isFavorite: false,
      companyPage: null,
      portfolio: null,
      stocksDropDownValues: [],
      cmpStckTickers: null,
      comparisionResult: null,

      symbolsList: [],
      gurusList: [],
      countriesList: [],
      portfolioImageUrl: null,

      showIndex: true,
    };
    //console.log("Home:", this.props.userEmail);
  }

  async componentDidMount() {
    let intializeURL = Constants.INITIALIZE_URL;

    const [initializeResponse] = await Promise.all([axios.get(intializeURL)]);
    //console.log("Countries List", initializeResponse.data.countriesList);
    this.setState({
      symbolsList: initializeResponse.data.symbolsList,
      gurusList: initializeResponse.data.gurusList,
      countriesList: initializeResponse.data.countriesList,
    });
  }

  handleLogout = () => {
    ReactDOM.render(<MainHome />, document.getElementById("root"));
  };

  resetNewUser = () => {
    this.setState({ newUser: false });
  };

  setShowHelp = () => {
    this.setState({
      ...intialDisplayState,
      showHelp: true,
    });
  };

  setShowReleaseNotes = () => {
    this.setState({
      ...intialDisplayState,
      showReleaseNotes: true,
    });
  };

  setShowSubmitFeature = () => {
    this.setState({
      ...intialDisplayState,
      showSubmitFeature: true,
    });
  };

  setShowIPO = () => {
    this.setState({
      ...intialDisplayState,
      showIPO: true,
    });
  };

  setShowScreener = (portfolio, portfolioImageUrl) => {
    //alert("showScreener called");
    //console.log("setting loading to false and showScreener to true");
    //console.log("setShowScreener portfolioImageUrl :", portfolioImageUrl);
    this.setState({
      ...intialDisplayState,
      portfolio: portfolio,
      portfolioImageUrl: portfolioImageUrl,
      showScreener: true,
    });
  };

  setShowETFScreener = (portfolio, portfolioImageUrl) => {
    //alert("showScreener called");
    //console.log("setting loading to false and showScreener to true");
    this.setState({
      ...intialDisplayState,
      portfolio: portfolio,
      portfolioImageUrl: portfolioImageUrl,
      showETFScreener: true,
    });
  };

  setShowFavorites = (portfolio, portfolioImageUrl) => {
    //alert("showScreener called");
    //console.log("setting loading to false and showScreener to true");
    this.setState({
      ...intialDisplayState,
      portfolio: portfolio,
      portfolioImageUrl: portfolioImageUrl,
      showFavorites: true,
    });
  };

  handleBlog() {
    window.open(Constants.BLOG_URL, "_blank"); //to open new page
  }

  compStcksSubmit = () => {
    ////console.log(this.cmpStckTickers);
    var cmpStcksArr = this.cmpStckTickers;
    var urlVal =
      Constants.COMPARE_TCKRS_URL +
      cmpStcksArr +
      "?userEmail=" +
      this.props.userEmail;

    axios({
      method: "get",
      responseType: "json",
      url: urlVal,
    })
      .then((response) => {
        var compareResults = response.data.compareResults;

        var cmprResultsContent = (
          <CompareResults
            stckLst={compareResults}
            userEmail={this.props.userEmail}
          />
        );

        this.setState({
          comparisionResult: cmprResultsContent,
          ...intialDisplayState,
          showCmprStcks: true,
        });
      })
      .catch(function (error) {
        //console.log("Home:compStcksSubmit app response Error:", error);
      });
  };

  compareStocks = () => {
    var stocksDropDownValuesArr = [];
    Object.values(this.state.symbolsList).forEach(function (compObj) {
      stocksDropDownValuesArr.push(compObj.title);
    });
    this.setState({
      ...intialDisplayState,
      showCmprStcks: true,

      cmpStckTickers: [],
      comparisionResult: "",
      stocksDropDownValues: stocksDropDownValuesArr,
    });
  };

  handleCmprStcks = (arr) => {
    this.cmpStckTickers = arr;
  };

  reloadHomePage = () => {
    this.setState({
      newUser: false,
      ...intialDisplayState,
      showIndex: true,
    });
  };

  // ** Function to handle Selected Ticker from search
  handleResultSelect = (e, { result }) => {
    ////console.log("In handleResultSet", result.title);
    this.setState({
      symbolValue: result.title,
    });
    srchVal = result.title;
    ////console.log("Search Symbol:", srchVal);
    this.onSubmit();
  };

  // ** Function to handle changed ticker
  handleSearchChange = (e, { value }) => {
    this.setState({ isLoading: true, symbolValue: value });

    setTimeout(() => {
      if (this.state.symbolValue.length < 1)
        return this.setState(...initialSearchState);

      const re = new RegExp("^" + _.escapeRegExp(this.state.symbolValue), "i");
      const isTMatch = (result) => re.test(result.title);
      const tR = _.filter(this.state.symbolsList, isTMatch);

      const reDesc = new RegExp(_.escapeRegExp(this.state.symbolValue), "i");
      const isDMatch = (result) => reDesc.test(result.description);
      const dR = _.filter(this.state.symbolsList, isDMatch);

      this.setState({
        isLoading: false,
        symbolResults: [...new Set(tR.concat(dR))],
      });
    }, 300);
  };

  // ** Function to handle changed country change
  handleCountryChange = (e, { value }) => {
    this.setState({ countryValue: value });

    setTimeout(() => {
      if (this.state.countryValue.length < 1)
        return this.setState(...initialSearchState);

      const re = new RegExp("^" + _.escapeRegExp(this.state.countryValue), "i");
      const isTMatch = (result) => re.test(result.title);
      const selectedCountry = _.filter(this.state.countriesList, isTMatch);

      this.setState({
        countryResults: [...new Set(selectedCountry)],
      });
    }, 300);
  };

  // ** Function to handle Selected Ticker from search
  handleCountryResultSelect = (e, { result }) => {
    //console.log("In handleResultSet", result);
    this.setState({
      countryValue: result.title,
    });
    ////console.log("Search Symbol:", srchVal);
    var urlVal =
      Constants.COUNTRY_URL +
      result.title +
      "?userEmail=" +
      this.props.userEmail;

    this.fetchScreenerData(urlVal, this.setShowScreener);
  };

  // ** Function to handle Selected Ticker from search
  handleCountrySelect = (event) => {
    //console.log("In handleCountrySelect", event.target.innerText);

    var urlVal =
      Constants.COUNTRY_URL +
      event.target.innerText +
      "?userEmail=" +
      this.props.userEmail;

    let countryCode = event.target.getAttribute("name");

    //console.log(
    //  "handleCountrySelect countryCode:",
    //event.target.getAttribute("name")
    //);

    const countryImageUrl =
      "https://www.countryflags.io/" + countryCode + "/shiny/64.png";

    //console.log("handleCountrySelect:", event.target.getAttribute("name"));

    //console.log("handleCountrySelect:", countryImageUrl);

    this.fetchScreenerData(urlVal, this.setShowScreener, countryImageUrl);
  };

  handleSearchActivity = () => {
    this.setState({
      ...intialDisplayState,
      showActivity: true,
    });
  };

  // ** Fuction for button on click

  // ** Fuction for button on click
  handleIdeas = (event) => {
    var urlVal =
      Constants.SCREENER_URL +
      event.target.innerText +
      "?userEmail=" +
      this.props.userEmail;

    this.fetchScreenerData(urlVal, this.setShowScreener);
  };

  handleIndex = (name) => {
    let url =
      Constants.COATTAIL_URL + name + "?userEmail=" + this.props.userEmail;

    this.fetchScreenerData(url, this.setShowScreener);
  };

  handleCoatTailScreener = (event) => {
    let url =
      Constants.COATTAIL_URL +
      event.target.getAttribute("name") +
      "?userEmail=" +
      this.props.userEmail;

    this.fetchScreenerData(url, this.setShowScreener);
  };

  handleRecentIPOs = (event) => {
    let url = Constants.RECENT_IPOS_URL + "?userEmail=" + this.props.userEmail;

    this.fetchScreenerData(url, this.setShowScreener);
  };

  handleSectorScreener = (event) => {
    var urlVal =
      Constants.SECTOR_SCREENER_URL +
      event.target.innerText +
      "?userEmail=" +
      this.props.userEmail;

    this.fetchScreenerData(urlVal, this.setShowScreener);
  };

  handleSectorETFScreener = (event) => {
    let url =
      Constants.COATTAIL_URL +
      event.target.getAttribute("name") +
      "?userEmail=" +
      this.props.userEmail;

    this.fetchScreenerData(url, this.setShowETFScreener);
  };

  handleIndustryScreener = (event) => {
    //console.log("Click happened" + event.target.value);
    var urlVal =
      Constants.INDUSTRY_SCREENER_URL +
      event.target.innerText +
      "?userEmail=" +
      this.props.userEmail;

    this.fetchScreenerData(urlVal, this.setShowScreener);
  };

  handleFavorites = () => {
    var urlVal =
      Constants.SCREENER_URL + "Favorites?userEmail=" + this.props.userEmail;

    this.fetchScreenerData(urlVal, this.setShowFavorites);
  };

  handleFullScreener = () => {
    //console.log("Click happened");
    var urlVal =
      Constants.SCREENER_URL + "all?userEmail=" + this.props.userEmail;

    this.fetchScreenerData(urlVal, this.setShowScreener);
  };

  handleETFScreener = () => {
    //console.log("Click happened");
    var urlVal =
      Constants.SCREENER_URL + "etfall?userEmail=" + this.props.userEmail;

    this.fetchScreenerData(urlVal, this.setShowETFScreener);
  };

  handleEarningsCalendar = (event) => {
    this.setState({
      ...intialDisplayState,
      showEarningsCal: true,
    });
  };

  handlePortfolioPerformance = (event) => {
    this.setState({
      ...intialDisplayState,
      showPortfolioLeaderboard: true,
    });
  };

  handleDeepDiveReport = (event) => {
    this.setState({
      ...intialDisplayState,
      showDeepDiveReport: true,
    });
  };

  async fetchScreenerData(url, displayBlockFn, portfolioImageUrl) {
    //console.log("setting loading to true", portfolioImageUrl);

    this.setState({
      loading: true,
    });

    const [response] = await Promise.all([axios.get(url)]);

    displayBlockFn(response.data.portfolio, portfolioImageUrl);
  }

  // ** Function to tab selection
  handleItemClick = (e, { name }) => {
    //console.log("Home:handleItemClick:", name);
    this.setState({
      activeItem: name,
    });
  };

  onSubmit = () => {
    //reset the page with empty
    this.setState({
      nodata: false,
      ...intialDisplayState,
      showStck: true,
    });

    if (srchVal === "") {
      alert("Please enter a stock Symbol !!");
      return false;
    }

    this.setState({
      loading: true,
    });

    var companyPage = this.state.loading ? (
      <LoadingSpinner />
    ) : (
      <CompanyPage userEmail={this.props.userEmail} symbol={srchVal} />
    );
    this.setState({
      loading: false,
      nodata: false,
      companyPage: companyPage,
    });
  };

  ag_grid_cell_click = (event) => {
    var col_heading = event.colDef.field;
    if (col_heading === "symbol") {
      //alert(event.value);
      this.loadSymbol(event.value);
    }
  };

  loadSymbol = (symbol) => {
    this.setState({
      symbolValue: symbol,
    });
    srchVal = symbol;
    this.onSubmit();
  };

  render() {
    const {
      showStck,
      companyPage,
      activeItem,
      loading,
      isLoading,
      symbolValue,
      symbolResults,

      countryValue,
      countryResults,
      portfolio,
      showHelp,
      showActivity,
      showFavorites,
      showReleaseNotes,
      showIndex,
      showScreener,
      showETFScreener,
      showPortfolioLeaderboard,
      showDeepDiveReport,
      showCmprStcks,
      showSubmitFeature,
      showIPO,
      showEarningsCal,
      stocksDropDownValues,
      comparisionResult,
      portfolioImageUrl,
    } = this.state;

    let tempSignoutButton = "";

    if (window.location.hostname === "localhost") {
      tempSignoutButton = (
        <Menu.Item name="logout" active={activeItem === "logout"}>
          <Button
            size="small"
            className="ui blue button"
            onClick={this.handleLogout}
          >
            Logout
          </Button>
        </Menu.Item>
      );
    }

    let isFullScreener = portfolio
      ? portfolio.name === "all"
        ? true
        : false
      : false;

    return (
      <>
        <div
          style={{
            width: "100%",
            top: "0",
            height: "auto",
            position: "relative",
            zIndex: 1,
            backgroundColor: "#2C3539",
          }}
        >
          <Menu
            compact
            fluid
            inverted
            borderless
            stackable
            style={{
              backgroundColor: "#2C3539",
            }}
          >
            <Menu.Item>
              <Image
                style={{ cursor: "pointer" }}
                size="small"
                src={`${process.env.PUBLIC_URL}/images/simply.PNG`}
                as="a"
                onClick={this.reloadHomePage}
              />{" "}
            </Menu.Item>

            <Menu.Item name="search" active={activeItem === "search"}>
              <Search
                loading={isLoading}
                onResultSelect={this.handleResultSelect}
                onSearchChange={_.debounce(this.handleSearchChange, 550, {
                  leading: true,
                })}
                results={symbolResults}
                type="input"
                value={symbolValue}
                placeholder="Search Company Name or Ticker..."
              />
            </Menu.Item>
            <Menu.Item fitted>
              <Dropdown item text="Investment Ideas">
                <Dropdown.Menu>
                  <Dropdown.Item
                    value="Buy Rated Stocks dipped Today"
                    onClick={this.handleIdeas}
                  >
                    Buy Rated Stocks dipped Today
                  </Dropdown.Item>
                  <Dropdown.Item value="Bestofall" onClick={this.handleIdeas}>
                    Best of All
                  </Dropdown.Item>
                  <Dropdown.Item name="top50" onClick={this.handleIdeas}>
                    Top 50 Value Stocks
                  </Dropdown.Item>
                  <Dropdown.Item name="largeCap" onClick={this.handleIdeas}>
                    Large Cap Growth
                  </Dropdown.Item>
                  <Dropdown.Item name="smallMid" onClick={this.handleIdeas}>
                    Great Small &amp; Mid size Companies
                  </Dropdown.Item>
                  <Dropdown.Item name="gems20" onClick={this.handleIdeas}>
                    Gems under $20
                  </Dropdown.Item>
                  <Dropdown.Item name="dividend" onClick={this.handleIdeas}>
                    Good Dividend Stocks
                  </Dropdown.Item>
                  <Dropdown.Item name="speculative" onClick={this.handleIdeas}>
                    Speculative Growth Stocks
                  </Dropdown.Item>
                  <Dropdown.Item name="highPFStocks" onClick={this.handleIdeas}>
                    High Performing Stocks
                  </Dropdown.Item>
                  <Dropdown.Item
                    name="highProfitMargin"
                    onClick={this.handleIdeas}
                  >
                    High Profit Margin Stocks
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>
            <Menu.Item fitted>
              <Dropdown item text="Model Portfolios">
                <Dropdown.Menu>
                  <Dropdown item text="Gurus">
                    <Dropdown.Menu>
                      {this.state.gurusList &&
                        this.state.gurusList
                          .filter((item) => item.type === "Investment Guru")
                          .map((item) => (
                            <Dropdown.Item
                              key={item.guru}
                              name={item.guru}
                              onClick={this.handleCoatTailScreener}
                            >
                              {item.guru}
                            </Dropdown.Item>
                          ))}
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown item text="Fintwit Gurus">
                    <Dropdown.Menu>
                      {this.state.gurusList &&
                        this.state.gurusList
                          .filter((item) => item.type === "Social Media Guru")
                          .map((item) => (
                            <Dropdown.Item
                              key={item.guru}
                              name={item.guru}
                              onClick={this.handleCoatTailScreener}
                            >
                              {item.guru}
                            </Dropdown.Item>
                          ))}
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown item text="V Line">
                    <Dropdown.Menu>
                      {this.state.gurusList &&
                        this.state.gurusList
                          .filter((item) => item.type === "Value Line")
                          .map((item) => (
                            <Dropdown.Item
                              key={item.guru}
                              name={item.guru}
                              onClick={this.handleCoatTailScreener}
                            >
                              {item.guru}
                            </Dropdown.Item>
                          ))}
                    </Dropdown.Menu>
                  </Dropdown>

                  <Dropdown.Item
                    name="2024 Watchlist"
                    onClick={this.handleCoatTailScreener}
                  >
                    2024 Watchlist
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>
            <Menu.Item fitted>
              <Dropdown item text="Special Screeners">
                <Dropdown.Menu>
                  <Dropdown.Item
                    name="Index and Sector Tracker"
                    onClick={this.handleSectorETFScreener}
                  >
                    Index and Sector Tracker
                  </Dropdown.Item>
                  <Dropdown item text="Sectors">
                    <Dropdown.Menu>
                      <Dropdown.Item
                        name="Technology"
                        onClick={this.handleSectorScreener}
                      >
                        Technology
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="Basic Materials"
                        onClick={this.handleSectorScreener}
                      >
                        Basic Materials
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="Communication Services"
                        onClick={this.handleSectorScreener}
                      >
                        Communication Services
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="Consumer Cyclical"
                        onClick={this.handleSectorScreener}
                      >
                        Consumer Cyclical
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="Consumer Defensive"
                        onClick={this.handleSectorScreener}
                      >
                        Consumer Defensive
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="Energy"
                        onClick={this.handleSectorScreener}
                      >
                        Energy
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="Financial"
                        onClick={this.handleSectorScreener}
                      >
                        Financial
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="Healthcare"
                        onClick={this.handleSectorScreener}
                      >
                        Healthcare
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="Industrials"
                        onClick={this.handleSectorScreener}
                      >
                        Industrials
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="Real Estate"
                        onClick={this.handleSectorScreener}
                      >
                        Real Estate
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown item text="Industries">
                    <Dropdown.Menu>
                      <Dropdown.Item
                        name="Residential Construction"
                        onClick={this.handleIndustryScreener}
                      >
                        Residential Construction
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="Restaurants"
                        onClick={this.handleIndustryScreener}
                      >
                        Restaurants
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="Lodging"
                        onClick={this.handleIndustryScreener}
                      >
                        Lodging
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="Airlines"
                        onClick={this.handleIndustryScreener}
                      >
                        Airlines
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="Resorts &amp; Casinos"
                        onClick={this.handleIndustryScreener}
                      >
                        Resorts &amp; Casinos
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="Travel &amp; Leisure"
                        onClick={this.handleIndustryScreener}
                      >
                        Travel &amp; Leisure
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="Travel Services"
                        onClick={this.handleIndustryScreener}
                      >
                        Travel Services
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="Grocery Stores"
                        onClick={this.handleIndustryScreener}
                      >
                        Grocery Stores
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="Discount Stores"
                        onClick={this.handleIndustryScreener}
                      >
                        Discount Stores
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="Semiconductor"
                        onClick={this.handleIndustryScreener}
                      >
                        Semiconductor
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="Pharma Companies"
                        onClick={this.handleCoatTailScreener}
                      >
                        Pharma Companies
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="Shell Companies"
                        onClick={this.handleSectorScreener}
                      >
                        Shell Companies
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="Cannabis"
                        onClick={this.handleCoatTailScreener}
                      >
                        Cannabis
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="Space Exploration"
                        onClick={this.handleCoatTailScreener}
                      >
                        Space Exploration
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown item text="Country" scrolling>
                    <Dropdown.Menu>
                      {this.state.countriesList &&
                        this.state.countriesList.map((item) => (
                          <Dropdown.Item
                            key={item.code}
                            name={item.code}
                            onClick={this.handleCountrySelect}
                          >
                            {item.value}
                          </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                  </Dropdown>

                  <Dropdown item text="Ev Related">
                    <Dropdown.Menu>
                      <Dropdown.Item
                        name="Electric Vehicles"
                        onClick={this.handleCoatTailScreener}
                      >
                        Electric Vehicles
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="Battery-Charging"
                        onClick={this.handleCoatTailScreener}
                      >
                        Battery-Charging
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="Natural Resources"
                        onClick={this.handleCoatTailScreener}
                      >
                        Natural Resources
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="LIDAR"
                        onClick={this.handleCoatTailScreener}
                      >
                        LIDAR
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="EV Resources"
                        onClick={this.handleCoatTailScreener}
                      >
                        EV Resources
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown item text="Clean Energy">
                    <Dropdown.Menu>
                      <Dropdown.Item
                        name="Solar"
                        onClick={this.handleCoatTailScreener}
                      >
                        Solar
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="Fuel Cell"
                        onClick={this.handleCoatTailScreener}
                      >
                        Fuel Cell
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown item text="Speciality Tech">
                    <Dropdown.Menu>
                      <Dropdown.Item
                        name="Data Tech"
                        onClick={this.handleCoatTailScreener}
                      >
                        Data Tech
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="Internet Retail"
                        onClick={this.handleCoatTailScreener}
                      >
                        Internet Retail
                      </Dropdown.Item>

                      <Dropdown.Item
                        name="Health Tech"
                        onClick={this.handleCoatTailScreener}
                      >
                        Health Tech
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="Real Estate Tech"
                        onClick={this.handleCoatTailScreener}
                      >
                        Real Estate Tech
                      </Dropdown.Item>

                      <Dropdown.Item
                        name="Fin Tech"
                        onClick={this.handleCoatTailScreener}
                      >
                        Fin Tech
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="Bio Tech"
                        onClick={this.handleCoatTailScreener}
                      >
                        Bio Tech
                      </Dropdown.Item>

                      <Dropdown.Item
                        name="Cloud Tech"
                        onClick={this.handleCoatTailScreener}
                      >
                        Cloud Tech
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="5g Tech"
                        onClick={this.handleCoatTailScreener}
                      >
                        5g Tech
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="AI Robotics Tech"
                        onClick={this.handleCoatTailScreener}
                      >
                        AI Robotics Tech
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="Advertisement Tech"
                        onClick={this.handleCoatTailScreener}
                      >
                        Advertisement Tech
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  <Dropdown.Item
                    name="FAANG"
                    onClick={this.handleCoatTailScreener}
                  >
                    FAANG
                  </Dropdown.Item>
                  <Dropdown.Item
                    name="Magnificent 7"
                    onClick={this.handleCoatTailScreener}
                  >
                    Magnificent 7
                  </Dropdown.Item>
                  <Dropdown.Item
                    name="TAMANA"
                    onClick={this.handleCoatTailScreener}
                  >
                    TAMANA
                  </Dropdown.Item>
                  <Dropdown.Item
                    name="Led by Indian CEOs"
                    onClick={this.handleCoatTailScreener}
                  >
                    Led by Indian CEOs
                  </Dropdown.Item>
                  <Dropdown.Item
                    name="GLP-1 Drug Companies"
                    onClick={this.handleCoatTailScreener}
                  >
                    GLP-1 Drug Companies
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>
            <Menu.Item fitted>
              <Dropdown item text="My Lists">
                <Dropdown.Menu>
                  <Dropdown.Item name="watchList" onClick={this.handleIdeas}>
                    Favorites
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>
            <Menu.Item fitted>
              <Dropdown item text="Screener">
                <Dropdown.Menu>
                  <Dropdown.Item
                    name="sp500_constituent"
                    onClick={this.handleCoatTailScreener}
                  >
                    S &amp; P 500 Index Stocks
                  </Dropdown.Item>
                  <Dropdown.Item
                    name="dowjones_constituent"
                    onClick={this.handleCoatTailScreener}
                  >
                    Dow Jones Index Stocks
                  </Dropdown.Item>
                  <Dropdown.Item
                    name="nasdaq_constituent"
                    onClick={this.handleCoatTailScreener}
                  >
                    NASDAQ Index Stocks
                  </Dropdown.Item>
                  <Dropdown.Item
                    name="Russel Small Cap 2000 Stocks"
                    onClick={this.handleCoatTailScreener}
                  >
                    Russel Small Cap Index Stocks
                  </Dropdown.Item>
                  <Dropdown.Item
                    name="IPOs in last one year"
                    onClick={this.handleRecentIPOs}
                  >
                    IPOs in last one year
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>
            <Menu.Item fitted>
              <Dropdown item text="Tools">
                <Dropdown.Menu>
                  <Dropdown.Item
                    name="performance"
                    onClick={this.handlePortfolioPerformance}
                  >
                    Portfolio Leaderboard
                  </Dropdown.Item>

                  <Dropdown.Item name="compare" onClick={this.compareStocks}>
                    Compare Stocks
                  </Dropdown.Item>
                  <Dropdown.Item name="ipo" onClick={this.setShowIPO}>
                    IPO Calendar
                  </Dropdown.Item>
                  <Dropdown.Item
                    name="earningsCal"
                    onClick={this.handleEarningsCalendar}
                  >
                    Earnings Calendar
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>
            <Menu.Item fitted>
              <Dropdown item text="Help">
                <Dropdown.Menu>
                  <Dropdown.Item value="siteHelp" onClick={this.setShowHelp}>
                    Site Help
                  </Dropdown.Item>
                  <Dropdown.Item
                    name="releaseNotes"
                    onClick={this.setShowReleaseNotes}
                  >
                    What's New
                  </Dropdown.Item>
                  <Dropdown.Item
                    name="submitFeature"
                    onClick={this.setShowSubmitFeature}
                  >
                    Submit Feature
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>

            <Menu.Item fitted position="right">
              <Dropdown item text={this.props.userName}>
                <Dropdown.Menu>
                  <Dropdown.Item
                    name="Favorites"
                    onClick={this.handleFavorites}
                  >
                    <Icon name="like" />
                    Favorites
                  </Dropdown.Item>

                  <Dropdown.Item name="signout" onClick={this.handleLogout}>
                    <Icon name="log out" />
                    Sign out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>
          </Menu>
        </div>
        {/* code for Company Tabs */}
        <div
          style={{
            width: "auto",
            minHeight: "200vh",
            display: showStck ? "block" : "none",
          }}
        >
          <div id="companypage">
            {loading ? <LoadingSpinner /> : companyPage}
          </div>
        </div>
        {/* code for Aggrid screener */}
        <div
          style={{
            display: showScreener ? "block" : "none",
          }}
        >
          <div
            style={{
              width: "auto",
            }}
          >
            {loading ? (
              <LoadingSpinner />
            ) : !isFullScreener ? (
              <ScreenerContainer
                userEmail={this.props.userEmail}
                portfolio={this.state.portfolio}
                agGridFn={this.ag_grid_cell_click}
                snapShotFn={this.loadSymbol}
                portfolioImageUrl={portfolioImageUrl}
              />
            ) : (
              <StockScreener
                userEmail={this.props.userEmail}
                portfolio={this.state.portfolio}
                portfolioImageUrl={portfolioImageUrl}
              />
            )}
          </div>
        </div>
        {/* code for ETF screener */}
        <div
          style={{
            display: showETFScreener ? "block" : "none",
          }}
        >
          <div
            style={{
              width: "auto",
              marginTop: "4.5%",
            }}
          >
            {loading ? (
              <LoadingSpinner />
            ) : (
              <ETFScreener
                userEmail={this.props.userEmail}
                portfolio={portfolio}
                agGridFn={this.ag_grid_cell_click}
              />
            )}
          </div>
        </div>

        {/* code for Performance Report */}
        <div
          style={{
            marginTop: "5%",
            marginLeft: "10%",
            marginRight: "10%",
            marginBottom: "1%",

            display: showPortfolioLeaderboard ? "block" : "none",
          }}
        >
          <PortfolioPerformance />
        </div>

        {/*Code for Submit Feature */}
        <div
          style={{
            height: "84vh",
            display: showSubmitFeature ? "block" : "none",
          }}
        >
          <SubmitFeature
            name={this.props.name}
            email={this.props.userEmail}
            contactURL={Constants.FEATURE_REQUEST_URL}
            header="Please submit a feature request or feedback"
          />
        </div>
        {/*Code for user guide */}
        <div
          style={{
            height: "150vh",
            marginTop: "5%",
            marginLeft: "10%",
            marginRight: "10%",
            display: showHelp ? "block" : "none",
          }}
        >
          <Help />
        </div>
        {/*Code for release notes */}
        <div
          style={{
            marginTop: "6%",
            marginBottom: "2%",
            marginLeft: "10%",
            marginRight: "10%",
            minHeight: "80vh",
            display: showReleaseNotes ? "block" : "none",
          }}
        >
          <ReleaseNotes />
        </div>
        {/* Favorites code */}
        <div
          style={{
            marginTop: "5%",
            width: "75%",
            marginLeft: "12.5%",
            height: "80%",
            display: showFavorites ? "block" : "none",
          }}
        >
          <SnapShots
            stockReports={
              this.state.portfolio ? this.state.portfolio.stockReports : null
            }
            displayAll={true}
            snapShotFn={this.loadSymbol}
          />
        </div>
        {/*Code for Index */}

        <div
          id="container"
          style={{
            minHeight: !this.state.newUser ? "300vh" : "70vh",
            marginTop: "5%",
            textAlign: "center",
            justifyContent: "center",
            display: showIndex ? "block" : "none",
          }}
        >
          {!this.state.newUser ? (
            <HomeContainer
              onClickIndexFunction={this.handleIndex}
              onClickSymbolFunction={this.loadSymbol}
            />
          ) : (
            <WelcomePage userName={this.props.userName} />
          )}
        </div>
        <div
          style={{
            minHeight: "600vh",
            height: "74vh",
            marginTop: "5%",
            textAlign: "center",
            marginLeft: "10%",
            marginRight: "10%",

            display: showIPO ? "block" : "none",
          }}
        >
          <IPOCalendar />
        </div>

        <div
          style={{
            minHeight: "600vh",
            height: "74vh",
            marginTop: "5%",
            textAlign: "center",
            marginLeft: "2%",
            marginRight: "2%",

            display: showEarningsCal ? "block" : "none",
          }}
        >
          <EarningsCalendar userEmail={this.props.userEmail} />
        </div>

        {/*Code for Compare stocks */}
        <div
          style={{
            height: "150vh",
            width: "auto",
            marginTop: "5%",
            display: showCmprStcks ? "block" : "none",
          }}
        >
          <div
            style={{
              marginLeft: "30%",
              marginRight: "30%",
            }}
          >
            <Card fluid raised>
              <Card.Content>
                <Card.Description>
                  <Header color="blue" textAlign="center" as="h2">
                    Compare Stocks
                  </Header>
                </Card.Description>
              </Card.Content>
            </Card>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <MultiSearch
              searchable={true}
              showTags={true}
              multiSelect={true}
              width="570px"
              onSelect={this.handleCmprStcks}
              options={stocksDropDownValues}
              searchPlaceholder={"Select Max 4 Symbols to compare"}
              primaryColor={"#FFFFFF"}
            />
            <div>
              <br />
              <Button
                size="large"
                className="ui blue button"
                onClick={this.compStcksSubmit}
              >
                Compare
              </Button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1%",
              marginBottom: "1%",
            }}
          >
            {comparisionResult}
          </div>
        </div>
      </>
    );
  }
}

import axios from "axios";
import React, { useState, useEffect } from "react";
import { Icon, Message, Tab, Modal, Button, Table } from "semantic-ui-react";
import "../../node_modules/react-vis/dist/style.css";
import "../css/home.css";
import Constants from "../util/Constants";
import GainersLosers from "./GainersLosers";
import SectorGainersLosers from "./SectorGainersLosers";
import Markets from "./Markets";
import SectorMap from "./SectorMap";
import IndexMap from "./IndexMap";
import Legend from "./Legend";
import CompanyPage from "../company/CompanyPage";
import LoadingSpinner from "../util/LoadingSpinner";

export default function HomeContainer(props) {
  const [stateValues, setstateValues] = useState({
    open: false,
    indexList: [],
    gainersLosers: [],
    modalSymbol: "",
    loading: true,
  });

  const showModal = (symbol) => {
    setstateValues({ ...stateValues, modalSymbol: symbol, open: true });
  };

  const setOpen = (flag) => {
    setstateValues({ ...stateValues, open: flag });
  };

  useEffect(() => {
    /*  console.log(
      "Use Effect is reloading markets data***************************"
    ); */
    let indexURL = Constants.INDEX_URL;
    let gainersLosersURL = Constants.LEADERS_URL;
    let goldURL = Constants.GOLD_URL;
    let silverURL = Constants.SILVER_URL;
    let oilURL = Constants.OIL_URL;
    let cryptoURL = Constants.CRYPTO_URL;

    const fetchData = async () => {
      /* console.log(
        "Use Effect is reloading markets data***************************"
      ); */
      const indexResponse = await axios(indexURL);
      const gainersLosersResponse = await axios(gainersLosersURL);
      const goldQuote = await axios(goldURL);
      const silverQuote = await axios(silverURL);
      const oilQuote = await axios(oilURL);
      const bitcoinQuote = await axios(cryptoURL);

      setstateValues({
        indexList: indexResponse.data.filter(
          (dataItem) =>
            dataItem.name === "Dow Jones Industrial Average" ||
            dataItem.name === "NASDAQ Composite" ||
            dataItem.name === "S&P 500" ||
            dataItem.name === "Russell 2000" ||
            dataItem.name === "CBOE Volatility Index"
        ),
        gainersLosers: gainersLosersResponse.data.gainersLosers,
        gold: goldQuote.data,
        silver: silverQuote.data,
        oil: oilQuote.data,
        bitcoin: bitcoinQuote.data,
      });
    };

    fetchData();

    /* const interval = setInterval(() => {
      fetchData();
    }, 10000);

    return () => clearInterval(interval); */
  }, []);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div
        id="container"
        style={{
          justifyContent: "center",
        }}
      >
        <div
          id="markets"
          style={{
            width: "80%",
            marginTop: "100px",
            marginLeft: "10%",
            marginRight: "10%",
          }}
        >
          <Markets
            indexList={stateValues.indexList}
            gold={stateValues.gold}
            silver={stateValues.silver}
            oil={stateValues.oil}
            bitcoin={stateValues.bitcoin}
            onClickIndexFunction={props.onClickIndexFunction}
          />
        </div>
        <div
          style={{
            marginTop: "1%",
            textAlign: "center",
          }}
        >
          <Message positive compact size="large">
            <Icon name="hand point up"></Icon>
            Click on the index above to see the stocks included in that Index
          </Message>
        </div>
        <Table unstackable compact style={{ border: "none" }}>
          <Table.Row>
            <Table.Cell>
              <div
                id="tabs"
                style={{
                  width: "85%",
                  marginLeft: "7.5%",
                }}
              >
                <Legend title={props.title} />
              </div>
              <div
                id="tabs"
                style={{
                  width: "85%",
                  marginLeft: "7.5%",
                }}
              >
                <Tab
                  menu={{
                    inverted: true,
                    attached: "top",
                    stackable: true,
                  }}
                  panes={[
                    {
                      menuItem: "Index Gainers and Losers",
                      render: () =>
                        stateValues.loading ? (
                          <LoadingSpinner mleft="0%" />
                        ) : (
                          <Tab.Pane>
                            <div
                              style={{
                                marginLeft: "20%",
                                justifyContent: "center",
                              }}
                            >
                              <GainersLosers
                                gainersLosers={stateValues.gainersLosers}
                                onClickSymbolFunction={showModal}
                              />
                            </div>
                          </Tab.Pane>
                        ),
                    },
                    {
                      menuItem: "Sector Gainers and Losers",
                      render: () => (
                        <Tab.Pane>
                          <div
                            style={{
                              marginLeft: "12%",
                              justifyContent: "center",
                            }}
                          >
                            <SectorGainersLosers
                              gainersLosers={stateValues.gainersLosers}
                              onClickSymbolFunction={showModal}
                            />
                          </div>
                        </Tab.Pane>
                      ),
                    },

                    {
                      menuItem: "Top 10 by Sector",
                      render: () => (
                        <div id="bySector" style={{}}>
                          <Tab.Pane>
                            <SectorMap
                              gainersLosers={stateValues.gainersLosers}
                              sectorMap={
                                stateValues.gainersLosers.quotesBySector
                              }
                              title="Performance of top 10 companies by market value in each sector"
                              onClickSymbolFunction={showModal}
                            />
                          </Tab.Pane>
                        </div>
                      ),
                    },
                    {
                      menuItem: "Dow by Sector",
                      render: () => (
                        <div id="bySector" style={{}}>
                          <Tab.Pane>
                            <SectorMap
                              sectorMap={
                                stateValues.gainersLosers.dowQuotesBySector
                              }
                              title="Performance of Dow 30 companies in each sector"
                              onClickSymbolFunction={showModal}
                              cellsPerRow={15}
                            />
                          </Tab.Pane>
                        </div>
                      ),
                    },
                    {
                      menuItem: "NASDAQ by Sector",
                      render: () => (
                        <div id="bySector" style={{}}>
                          <Tab.Pane>
                            <SectorMap
                              sectorMap={
                                stateValues.gainersLosers.nasdaqQuotesBySector
                              }
                              title="Performance of NASDAQ 100 companies in each sector"
                              onClickSymbolFunction={showModal}
                            />
                          </Tab.Pane>
                        </div>
                      ),
                    },
                    {
                      menuItem: "S & P by Sector",
                      render: () => (
                        <div id="bySector" style={{}}>
                          <Tab.Pane>
                            <SectorMap
                              sectorMap={
                                stateValues.gainersLosers.spQuotesBySector
                              }
                              title="Performance of S &amp; P 500 companies in each sector"
                              onClickSymbolFunction={showModal}
                            />
                          </Tab.Pane>
                        </div>
                      ),
                    },
                    {
                      menuItem: "Dow Map",
                      render: () => (
                        <div id="dowmap" style={{}}>
                          <Tab.Pane>
                            <IndexMap
                              indexMap={stateValues.gainersLosers.dowList}
                              title="Performance of Dow 30 companies"
                              onClickSymbolFunction={showModal}
                            />
                          </Tab.Pane>
                        </div>
                      ),
                    },
                    {
                      menuItem: "NASDAQ Map",
                      render: () => (
                        <div id="nasdaqmap" style={{}}>
                          <Tab.Pane>
                            <IndexMap
                              indexMap={stateValues.gainersLosers.nasdaqList}
                              title="Performance of NASDAQ 100 companies"
                              onClickSymbolFunction={showModal}
                            />
                          </Tab.Pane>
                        </div>
                      ),
                    },
                    {
                      menuItem: "S & P Map",
                      render: () => (
                        <div id="spmap" style={{}}>
                          <Tab.Pane>
                            <IndexMap
                              indexMap={stateValues.gainersLosers.spList}
                              title="Performance of S &amp; P 500 companies"
                              onClickSymbolFunction={showModal}
                            />
                          </Tab.Pane>
                        </div>
                      ),
                    },
                  ]}
                />
              </div>
            </Table.Cell>
          </Table.Row>
        </Table>
      </div>
      <Modal
        closeOnEscape={true}
        centered={true}
        open={stateValues.open}
        size="fullscreen"
        style={{ marginLeft: "2%" }}
      >
        <Modal.Content scrolling>
          <Modal.Description>
            <CompanyPage symbol={stateValues.modalSymbol} />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button className="ui blue button" onClick={() => setOpen(false)}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}
